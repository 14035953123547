import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SingleItem from '@components/single';

export default function SingleAttachments({
  data: {
    attachments: { type, ...attachments },
  },
}) {
  const { data, tags } = attachments;
  return (
    <SingleItem
      tags={tags}
      single={{
        type,
        title: data.title.text,
        description: data.copy.html,
        image: data.image,
        category: data.category,
        prices: {
          sellingPrice: data.selling_price.text,
          fourHourCost: data.four_hour_price.text,
          dayCost: data.day_price.text,
          weekCost: data.week_price.text,
          monthCost: data.month_price.text,
        },
        relationships: data.equipment,
        specsPdf: data.specs_pdf,
        specs: data.specs,
        video: data.video,
        related:
          data.related?.length > 0 && data.related[0].item ? data.related : [],
      }}
    />
  );
}

SingleAttachments.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query singleAttachments($slug: String!) {
    attachments: prismicAttachments(uid: { eq: $slug }) {
      type
      tags
      uid
      data {
        title {
          text
        }
        copy {
          html
        }
        image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        coming_soon
        for_sale
        selling_price {
          text
        }
        four_hour_price {
          text
        }
        day_price {
          text
        }
        week_price {
          text
        }
        month_price {
          text
        }
        category {
          uid
          document {
            ... on PrismicCategories {
              data {
                name
              }
            }
          }
        }
        equipment {
          relationship {
            document {
              ... on PrismicEquipment {
                id
                uid
                data {
                  title {
                    text
                  }
                  copy {
                    text
                  }
                  coming_soon
                  for_sale
                  category {
                    uid
                  }
                  image {
                    url
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        }
        specs_pdf {
          url
        }
        specs {
          name {
            text
          }
          value {
            text
          }
        }
        video {
          embed_url
          html
        }
        related {
          item {
            uid
            document {
              ... on PrismicAttachments {
                type
                uid
                data {
                  title {
                    text
                  }
                  copy {
                    text
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  coming_soon
                  for_sale
                  selling_price {
                    text
                  }
                  four_hour_price {
                    text
                  }
                  day_price {
                    text
                  }
                  week_price {
                    text
                  }
                  month_price {
                    text
                  }
                  category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                  category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicEquipment {
                type
                uid
                data {
                  title {
                    text
                  }
                  copy {
                    text
                  }
                  image {
                    alt
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  coming_soon
                  for_sale
                  selling_price {
                    text
                  }
                  four_hour_price {
                    text
                  }
                  day_price {
                    text
                  }
                  week_price {
                    text
                  }
                  month_price {
                    text
                  }
                  category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                  category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                  sub_category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                }
              }
              ... on PrismicForSale {
                type
                uid
                data {
                  title {
                    text
                  }
                  copy {
                    text
                  }
                  image {
                    fluid {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  coming_soon
                  selling_price {
                    text
                  }
                  category {
                    uid
                    document {
                      ... on PrismicCategories {
                        data {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
